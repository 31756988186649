import type { ComponentPropsWithoutRef } from 'react';
import { type VariantProps, cn } from '~/modules/ui/cva';
import { cva } from '~/utils/css-utils';

const iconVariants = cva({
  base: 'inline-block shrink-0 fill-none stroke-[1.25] stroke-current',
  variants: {
    size: {
      sm: 'size-4',
      md: 'size-6',
      lg: 'size-8',
    },
  },
});

type IconVariants = VariantProps<typeof iconVariants>;

export type IconProps = Omit<
  ComponentPropsWithoutRef<'svg'>,
  'width' | 'height' | 'viewBox'
> &
  IconVariants;

export function Icon({
  children,
  className,
  size = 'md',
  ...props
}: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(iconVariants({ size }), className)}
      {...props}
      aria-hidden
    >
      {children}
    </svg>
  );
}

// Move these later if we use this code
export function PayIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <circle cx="9.77817" cy="14" r="5.5" fill="currentcolor" opacity="0.3" />
      <circle
        cx="11.8993"
        cy="11.8789"
        r="5.5"
        fill="currentcolor"
        opacity="0.6"
      />
      <circle cx="14.0204" cy="9.75732" r="5.5" fill="currentcolor" />
    </Icon>
  );
}

// Move these later if we use this code
export function GetPaidIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <circle cx="9.97964" cy="14.2427" r="5.5" fill="currentcolor" />
      <circle
        opacity="0.6"
        cx="12.1007"
        cy="12.1211"
        r="5.5"
        fill="currentcolor"
      />
      <circle opacity="0.3" cx="14.2218" cy="10" r="5.5" fill="currentcolor" />
    </Icon>
  );
}

// Move these later if we use this code
export function SparklesIcon({ className, ...props }: IconProps) {
  return (
    <Icon className={cn('stroke-none', className)} {...props}>
      <path
        opacity="0.4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.37979 4.66653C7.02983 4.66653 6.74613 4.95024 6.74613 5.3002C6.74613 5.62977 6.58311 5.99573 6.28961 6.28924C5.9961 6.58274 5.63014 6.74576 5.30057 6.74576C4.95061 6.74576 4.6669 7.02946 4.6669 7.37942C4.6669 7.72939 4.95061 8.01309 5.30057 8.01309C5.63014 8.01309 5.9961 8.1761 6.28961 8.46961C6.58311 8.76311 6.74613 9.12908 6.74613 9.45864C6.74613 9.80861 7.02983 10.0923 7.37979 10.0923C7.72976 10.0923 8.01346 9.80861 8.01346 9.45864C8.01346 9.12908 8.17647 8.76311 8.46998 8.46961C8.76348 8.1761 9.12945 8.01309 9.45901 8.01309C9.80898 8.01309 10.0927 7.72939 10.0927 7.37942C10.0927 7.02946 9.80898 6.74576 9.45901 6.74576C9.12945 6.74576 8.76348 6.58274 8.46998 6.28924C8.17647 5.99573 8.01346 5.62977 8.01346 5.3002C8.01346 4.95024 7.72976 4.66653 7.37979 4.66653Z"
        fill="currentcolor"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.37979 13.9075C7.02983 13.9075 6.74613 14.1912 6.74613 14.5412C6.74613 14.8707 6.58311 15.2367 6.28961 15.5302C5.9961 15.8237 5.63014 15.9867 5.30057 15.9867C4.95061 15.9867 4.6669 16.2704 4.6669 16.6204C4.6669 16.9704 4.95061 17.2541 5.30057 17.2541C5.63014 17.2541 5.9961 17.4171 6.28961 17.7106C6.58311 18.0041 6.74613 18.37 6.74613 18.6996C6.74613 19.0496 7.02983 19.3333 7.37979 19.3333C7.72976 19.3333 8.01346 19.0496 8.01346 18.6996C8.01346 18.37 8.17647 18.0041 8.46998 17.7106C8.76348 17.4171 9.12945 17.2541 9.45901 17.2541C9.80898 17.2541 10.0927 16.9704 10.0927 16.6204C10.0927 16.2704 9.80898 15.9867 9.45901 15.9867C9.12945 15.9867 8.76348 15.8237 8.46998 15.5302C8.17647 15.2367 8.01346 14.8707 8.01346 14.5412C8.01346 14.1912 7.72976 13.9075 7.37979 13.9075Z"
        fill="currentcolor"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9257 7.40767C13.9257 6.99845 14.2575 6.66672 14.6667 6.66672C15.0759 6.66672 15.4077 6.99845 15.4077 7.40767C15.4077 8.34391 15.8631 9.31211 16.6089 10.0579C17.3546 10.8036 18.3228 11.2591 19.2591 11.2591C19.6683 11.2591 20 11.5908 20 12.0001C20 12.4093 19.6683 12.741 19.2591 12.741C18.3228 12.741 17.3546 13.1965 16.6089 13.9422C15.8631 14.688 15.4077 15.6562 15.4077 16.5924C15.4077 17.0016 15.0759 17.3334 14.6667 17.3334C14.2575 17.3334 13.9257 17.0016 13.9257 16.5924C13.9257 15.6562 13.4703 14.688 12.7245 13.9422C11.9788 13.1965 11.0106 12.741 10.0743 12.741C9.6651 12.741 9.33336 12.4093 9.33336 12.0001C9.33336 11.5908 9.6651 11.2591 10.0743 11.2591C11.0106 11.2591 11.9788 10.8036 12.7245 10.0579C13.4703 9.31211 13.9257 8.34391 13.9257 7.40767Z"
        fill="currentcolor"
      />
    </Icon>
  );
}

export type Icon = typeof Icon;
