import { Separator } from '@radix-ui/react-separator';
import { useRouter } from 'next/router';
import { Route } from 'nextjs-routes';
import * as React from 'react';
import { useLogout } from '~/hooks/useLogout';
import { useViewer } from '~/hooks/useOrg';
import { usePublicConfig } from '~/hooks/usePublicConfig';
import {
  MenuName,
  removeOrgResourceFromRoute,
  useGetMenuNameForPathname,
  useGlobalNavigationCtx,
} from '~/modules/ui/components/global-navigation/global-navigation-context';
import { cn } from '~/modules/ui/cva';
import { Button, LinkButton } from '~/modules/ui/primitives/button';
import {
  ArrowDownLeftIcon,
  ArrowLeftIcon,
  ArrowUpRightCircleIcon,
  ArrowUpRightIcon,
  ArrowsIcon,
  AsteriskIcon,
  AvatarHexagonalIcon,
  BankIcon,
  BellIcon,
  BookOpenIcon,
  BuildingsIcon,
  CircleIcon,
  CreditCardIcon,
  DiamondIcon,
  HomeIcon,
  Layer3Icon,
  LogOutIcon,
  MailIcon,
  MoneyIcon,
  OpenPaneIcon,
  PenTool2Icon,
  PlusIcon,
  RefreshIcon,
  SettingsIcon,
  SparklesIcon,
  SpinnerIcon,
  SquareIcon,
  StampIcon,
  UsersIcon,
} from '~/modules/ui/primitives/icon';
import { VStack } from '~/modules/ui/primitives/stack';
import { assertUnreachable } from '~/utils/assertUnreachable';
import { deepEqual, isString } from '~/utils/utility';

function List({
  className,
  ...props
}: React.ComponentPropsWithoutRef<typeof VStack>) {
  return <VStack gap="0.5" className={cn('p-3', className)} {...props} />;
}

function ListSeparator({ className }: { className?: string }) {
  return (
    <Separator
      className={cn('mx-2.5 my-1.5 h-px bg-border/30', className)}
      orientation="horizontal"
    />
  );
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
type StaticRoute = Exclude<Route, { query: any }>['pathname'];

interface ListLinkProps
  extends React.ComponentPropsWithoutRef<typeof LinkButton> {
  matcher?: 'exact' | 'partial';
  direction?: 1 | -1;
  href: Route | StaticRoute;
}

function ListLink({
  matcher = 'partial',
  direction = 1,
  className,
  children,
  ...props
}: ListLinkProps) {
  const router = useRouter();
  const ctx = useGlobalNavigationCtx();
  const getMenuNameForPathname = useGetMenuNameForPathname();

  const pathname = isString(props.href) ? props.href : props.href.pathname;
  const query = isString(props.href) ? {} : props.href.query;

  const menu = getMenuNameForPathname(pathname);

  const isExactPathnameMatch = router.pathname === pathname;
  const isExactQueryMatch =
    !isString(props.href) && deepEqual(router.query, query);
  const isExactMatch = isExactPathnameMatch && isExactQueryMatch;

  const isPartialPathnameMatch = router.pathname.startsWith(pathname);
  const isPathnameVisibleMenu =
    ctx.routeMenuState.name === ctx.visibleMenuState.name;

  const isSelected =
    matcher === 'exact' ? isExactMatch : isPartialPathnameMatch;

  return (
    <LinkButton
      href={props.href}
      className={cn(
        'max-w-full self-start overflow-hidden [&>span]:has-[svg:first-child]:pl-2',
        className,
      )}
      intent={
        typeof props.intent !== 'undefined'
          ? props.intent
          : isSelected
            ? isPathnameVisibleMenu
              ? 'primary'
              : 'secondary'
            : 'ghost'
      }
      onPointerOver={() => ctx.direction.set(direction)}
      onClick={
        isSelected && !isPathnameVisibleMenu ? () => ctx.goTo(menu) : undefined
      }
    >
      {children}
    </LinkButton>
  );
}

function ListButton({
  children,
  intent = 'ghost',
  className,
  ...props
}: React.ComponentPropsWithoutRef<typeof Button>) {
  return (
    <Button
      className={cn(
        'max-w-full self-start overflow-hidden [&>span]:has-[svg:first-child]:pl-2',
        className,
      )}
      intent={intent}
      {...props}
    >
      {children}
    </Button>
  );
}

function ListPreviousButton(props: { to: MenuName }) {
  const ctx = useGlobalNavigationCtx();

  return (
    <ListButton intent="ghost" onClick={() => ctx.goTo(props.to, -1)}>
      <OpenPaneIcon />
    </ListButton>
  );
}

function ListLinkLabel({
  children,
  className,
  ...rest
}: React.ComponentPropsWithoutRef<'span'>) {
  return (
    <span className={cn('block truncate', className)} {...rest}>
      {children}
    </span>
  );
}

function GlobalDefaultMenu() {
  const viewer = useViewer();
  const ctx = useGlobalNavigationCtx();

  return (
    <List>
      <ListButton onClick={() => ctx.goTo('org-picker', 1)}>
        <SquareIcon
          style={{
            fill: viewer.organization.brandColor,
            stroke: viewer.organization.brandColor,
          }}
        />
        <span className="block truncate">
          {viewer.organization.displayName}
        </span>
      </ListButton>
      <ListSeparator />
      <ListLink href={viewer.pathFor('/')} matcher="exact">
        <HomeIcon />
        <ListLinkLabel>For you</ListLinkLabel>
      </ListLink>
      <ListLink href={viewer.pathFor('/assistant')}>
        <SparklesIcon />
        <ListLinkLabel>Assistant</ListLinkLabel>
      </ListLink>
      <ListLink href={viewer.pathFor('/pay')}>
        <ArrowUpRightIcon />
        <ListLinkLabel>Pay</ListLinkLabel>
      </ListLink>
      <ListLink href={viewer.pathFor('/get-paid')}>
        <ArrowDownLeftIcon />
        <ListLinkLabel>Get paid</ListLinkLabel>
      </ListLink>

      <ListLink href={viewer.pathFor('/contacts')}>
        <BookOpenIcon />
        <ListLinkLabel>Contacts</ListLinkLabel>
      </ListLink>
      <ListSeparator />
      <ListLink href={viewer.pathFor('/automation/accounting')}>
        <ArrowsIcon />
        <ListLinkLabel>Automation</ListLinkLabel>
      </ListLink>
      <ListLink href={viewer.pathFor('/settings')}>
        <SettingsIcon />
        <ListLinkLabel>Settings</ListLinkLabel>
      </ListLink>
    </List>
  );
}

function GlobalOrganizationMenu() {
  const viewer = useViewer();
  const router = useRouter();
  const logout = useLogout();

  const filtered = viewer.memberships.filter(
    (it) => it.organization.type === 'COMPANY',
  );

  return (
    <List>
      <ListPreviousButton to="default" />
      <ListSeparator />
      {filtered.map((it) => {
        return (
          <ListLink
            key={it.organization.id}
            href={removeOrgResourceFromRoute({
              pathname: router.pathname,
              query: {
                ...router.query,
                slug: it.organization.slug,
              },
            } as Route)}
            matcher="exact"
          >
            <SquareIcon
              style={{
                fill: it.organization.brandColor,
                stroke: it.organization.brandColor,
              }}
            />
            <ListLinkLabel>{it.organization.displayName}</ListLinkLabel>
          </ListLink>
        );
      })}
      <ListSeparator />
      {filtered.length > 1 && (
        <ListLink href="/entities">
          <BuildingsIcon />
          <ListLinkLabel>All organizations</ListLinkLabel>
        </ListLink>
      )}
      <ListLink href="/org/new">
        <PlusIcon />
        <ListLinkLabel>Add organization</ListLinkLabel>
      </ListLink>
      <ListSeparator />
      <ListButton disabled={logout.loading} onClick={() => logout.logout()}>
        {logout.loading ? (
          <SpinnerIcon className="animate-spin" />
        ) : (
          <LogOutIcon />
        )}
        <ListLinkLabel>Sign out</ListLinkLabel>
      </ListButton>
    </List>
  );
}

function GlobalContactMenu() {
  const viewer = useViewer();
  const router = useRouter();

  return (
    <List>
      <ListPreviousButton to="default" />
      <ListLink
        intent="secondary"
        href={viewer.pathFor('/contacts')}
        direction={-1}
      >
        <ArrowLeftIcon />
        <ListLinkLabel>Contacts</ListLinkLabel>
      </ListLink>
      <ListSeparator />
      <ListLink
        href={viewer.pathFor('/contacts/[contactId]', {
          contactId: router.query.contactId,
        })}
        shallow
        matcher="exact"
      >
        <ListLinkLabel>Overview</ListLinkLabel>
      </ListLink>
      <ListLink
        href={viewer.pathFor('/contacts/[contactId]', {
          contactId: router.query.contactId,
          tab: 'details',
        })}
        shallow
        matcher="exact"
      >
        <ListLinkLabel>Details</ListLinkLabel>
      </ListLink>
    </List>
  );
}

function GlobalRecurringReceivableAddMenu() {
  const viewer = useViewer();
  return (
    <List>
      <ListPreviousButton to="default" />
      <ListLink
        intent="secondary"
        href={viewer.pathFor('/get-paid/recurring')}
        direction={-1}
      >
        <ArrowLeftIcon />
        <span className="block truncate">Recurring</span>
      </ListLink>
    </List>
  );
}

function GlobalRecurringReceivableMenu() {
  const viewer = useViewer();
  const router = useRouter();

  return (
    <List>
      <ListPreviousButton to="default" />
      <ListLink
        intent="secondary"
        href={viewer.pathFor('/get-paid/recurring')}
        direction={-1}
      >
        <ArrowLeftIcon />
        <ListLinkLabel>Recurring</ListLinkLabel>
      </ListLink>
      <ListSeparator />
      <ListLink
        href={viewer.pathFor('/get-paid/recurring/[recurringId]', {
          recurringId: router.query.recurringId,
        })}
        matcher="exact"
      >
        <ListLinkLabel>Overview</ListLinkLabel>
      </ListLink>
      <ListLink
        href={viewer.pathFor('/get-paid/recurring/[recurringId]', {
          recurringId: router.query.recurringId,
          view: 'details',
        })}
        matcher="exact"
      >
        <ListLinkLabel>Details</ListLinkLabel>
      </ListLink>
      <ListLink
        href={viewer.pathFor('/get-paid/recurring/[recurringId]', {
          recurringId: router.query.recurringId,
          view: 'design',
        })}
        matcher="exact"
      >
        <ListLinkLabel>Design</ListLinkLabel>
      </ListLink>
    </List>
  );
}

function GlobalBillMenu() {
  const viewer = useViewer();

  return (
    <List>
      <ListPreviousButton to="default" />
      <ListLink intent="secondary" href={viewer.pathFor('/pay')} direction={-1}>
        <ArrowLeftIcon />
        <ListLinkLabel>Pay</ListLinkLabel>
      </ListLink>
    </List>
  );
}

function GlobalPayMenu() {
  const viewer = useViewer();
  const publicConfig = usePublicConfig();

  return (
    <List>
      <ListPreviousButton to="default" />
      <ListSeparator />
      <ListLink href={viewer.pathFor('/pay')} direction={-1}>
        <CircleIcon />
        <ListLinkLabel>Bills</ListLinkLabel>
      </ListLink>
      {/* We hide the financing tab in the demo environment */}
      {/* https://tola--hq.slack.com/archives/C02JEBY4THP/p1742313284161719 */}
      {publicConfig.APP_ENV !== 'demo' && (
        <ListLink href={viewer.pathFor('/financing')}>
          <AsteriskIcon />
          <ListLinkLabel>Financing</ListLinkLabel>
        </ListLink>
      )}
    </List>
  );
}

function GlobalGetPaidMenu() {
  const viewer = useViewer();

  return (
    <List>
      <ListPreviousButton to="default" />
      <ListSeparator />
      <ListLink href={viewer.pathFor('/get-paid')} matcher="exact">
        <CircleIcon />
        <ListLinkLabel>Invoices</ListLinkLabel>
      </ListLink>
      <ListLink href={viewer.pathFor('/get-paid/recurring')}>
        <RefreshIcon />
        <ListLinkLabel>Recurring</ListLinkLabel>
      </ListLink>
      {viewer.enabledFeatureFlags.includes('30-10-awesome-ar') && (
        <ListLink href={viewer.pathFor('/get-paid/inventory')}>
          <DiamondIcon />
          <ListLinkLabel>Library</ListLinkLabel>
        </ListLink>
      )}
      {viewer.enabledFeatureFlags.includes('ai-agreement-parsing') && (
        <ListLink href={viewer.pathFor('/get-paid/agreements')}>
          <PenTool2Icon />
          <ListLinkLabel>Agreements</ListLinkLabel>
        </ListLink>
      )}
    </List>
  );
}

function GlobalInvoiceMenu() {
  const viewer = useViewer();
  const router = useRouter();

  return (
    <List>
      <ListPreviousButton to="default" />
      <ListLink
        intent="secondary"
        href={viewer.pathFor('/get-paid')}
        direction={-1}
      >
        <ArrowLeftIcon />
        <ListLinkLabel>Get Paid</ListLinkLabel>
      </ListLink>
      <ListSeparator className="xl:hidden" />
      <ListLink
        className="xl:hidden"
        href={viewer.pathFor('/get-paid/[billId]', {
          billId: router.query.billId,
          ...(router.query.edit ? { edit: '1' } : {}),
        })}
        shallow
        matcher="exact"
      >
        <ListLinkLabel>Details</ListLinkLabel>
      </ListLink>
      {viewer.enabledFeatureFlags.includes('30-10-awesome-ar') && (
        <ListLink
          className="xl:hidden"
          href={viewer.pathFor('/get-paid/[billId]', {
            billId: router.query.billId,
            tab: 'preview',
            ...(router.query.edit ? { edit: '1' } : {}),
          })}
          shallow
          matcher="exact"
        >
          <ListLinkLabel>Design</ListLinkLabel>
        </ListLink>
      )}
    </List>
  );
}

function GlobalAutomationMenu() {
  const viewer = useViewer();

  return (
    <List>
      <ListPreviousButton to="default" />
      <ListSeparator />
      <ListLink href={viewer.pathFor('/automation/accounting')}>
        <RefreshIcon />
        <ListLinkLabel>Accounting</ListLinkLabel>
      </ListLink>
      <ListLink href={viewer.pathFor('/automation/approvals')}>
        <StampIcon />
        <ListLinkLabel>Approvals</ListLinkLabel>
      </ListLink>
      <ListLink href={viewer.pathFor('/automation/auto-payments')}>
        <ArrowUpRightCircleIcon />
        <ListLinkLabel>Auto payments</ListLinkLabel>
      </ListLink>
      <ListLink href={viewer.pathFor('/automation/email-forwarding')}>
        <MailIcon />
        <ListLinkLabel>Email forwarding</ListLinkLabel>
      </ListLink>
      <ListLink href={viewer.pathFor('/automation/reminders')}>
        <BellIcon />
        <ListLinkLabel>Reminders</ListLinkLabel>
      </ListLink>
    </List>
  );
}

function GlobalSettingsMenu() {
  const viewer = useViewer();

  return (
    <List>
      <ListPreviousButton to="default" />
      <ListLink href={viewer.pathFor('/settings/profile')}>
        <AvatarHexagonalIcon />
        <ListLinkLabel>Your profile</ListLinkLabel>
      </ListLink>
      <ListSeparator />
      <ListLink href={viewer.pathFor('/settings')} matcher="exact">
        <SettingsIcon />
        <ListLinkLabel>General</ListLinkLabel>
      </ListLink>
      <ListLink href={viewer.pathFor('/settings/address')} matcher="exact">
        <HomeIcon />
        <ListLinkLabel>Address</ListLinkLabel>
      </ListLink>
      <ListLink href={viewer.pathFor('/settings/limits')}>
        <Layer3Icon />
        <ListLinkLabel>Limits</ListLinkLabel>
      </ListLink>
      <ListLink href={viewer.pathFor('/settings/members')}>
        <UsersIcon />
        <ListLinkLabel>Members</ListLinkLabel>
      </ListLink>
      <ListSeparator />
      <ListLink href={viewer.pathFor('/settings/accounts')}>
        <BankIcon />
        <ListLinkLabel>Bank accounts</ListLinkLabel>
      </ListLink>
      <ListLink href={viewer.pathFor('/settings/cards')}>
        <CreditCardIcon />
        <ListLinkLabel>Cards</ListLinkLabel>
      </ListLink>
      <ListSeparator />
      <ListLink href={viewer.pathFor('/settings/refer')}>
        <MoneyIcon />
        <ListLinkLabel>Refer a company</ListLinkLabel>
      </ListLink>
      <ListLink href={viewer.pathFor('/settings/accountant')}>
        <SettingsIcon />
        <ListLinkLabel>Need a CPA?</ListLinkLabel>
      </ListLink>
    </List>
  );
}

export function MenuComponentForName({ name }: { name: MenuName }) {
  switch (name) {
    case 'default':
      return <GlobalDefaultMenu />;
    case 'org-picker':
      return <GlobalOrganizationMenu />;
    case 'pay':
      return <GlobalPayMenu />;
    case 'bill':
      return <GlobalBillMenu />;
    case 'get-paid':
      return <GlobalGetPaidMenu />;
    case 'invoice':
      return <GlobalInvoiceMenu />;
    case 'settings':
      return <GlobalSettingsMenu />;
    case 'contact':
      return <GlobalContactMenu />;
    case 'recurring':
      return <GlobalRecurringReceivableMenu />;
    case 'recurring-add':
      return <GlobalRecurringReceivableAddMenu />;
    case 'automation':
      return <GlobalAutomationMenu />;
    default:
      assertUnreachable(name);
  }
}
