import type { Simplify } from 'type-fest';
import { isDefined } from '~/utils/utility';

/**
 * Verify if the input is an object.
 */
export function isObject(value: unknown): value is Record<string, unknown> {
  return value !== null && !Array.isArray(value) && typeof value === 'object';
}

/**
 * Used JSON.stringify limitation with BigInt
 * It fixes `JSON.stringify() doesn't know how to serialize a BigInt`
 */
export function toObject(value: unknown) {
  if (typeof value === 'undefined') {
    return undefined;
  }
  return JSON.parse(
    JSON.stringify(value, (_, value) =>
      typeof value === 'bigint' ? value.toString() : value,
    ),
  );
}

type NonNull<T> = Exclude<T, null>;

export type NonNullRecord<T> = { [P in keyof T]: NonNull<T[P]> };

/**
 * nonNullRecord is a bit of a hack to get around the fact that the `vendor`
 * fields are nullable and our schema doesn't expect `null` values everywhere.
 * This makes sure the schema doesn't validate against `null`. This feels like a
 * code smell and should probably be deprecated and resolved.
 */

// biome-ignore lint/suspicious/noExplicitAny: ok
export function nonNullRecord<T extends Record<string, any | null | undefined>>(
  obj: T,
) {
  const result: Record<string, symbol | undefined> = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value !== null) {
      result[key] = value;
    }
  }
  return result as Simplify<NonNullRecord<T>>;
}

/**
 * Like `Object.keys` but with a type-safe return value.
 */
export function objectKeys<T extends object>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}
/**
 * Like `Object.entries` but with a type-safe return value.
 */
export function objectEntries<
  T extends { [Key in K]: string | number | symbol },
  K extends keyof T,
>(obj: T): Array<[K, T[K]]> {
  return Object.entries(obj) as Array<[K, T[K]]>;
}

type PropagateUndefined<TType, TNewType> = TType extends undefined
  ? TNewType | undefined
  : TNewType;

type PropagateNull<TType, TNewType> = TType extends null
  ? TNewType | null
  : TNewType;

/** Propagate methods is needed to be able to do this:
 * { date: Date} => {date : PlainDate}
 * { date ?: Date} => {date ?: PlainDate}
 * { date ?: Date | null} => {date ?: PlainDate | null}
 * { date : Date | undefined} => {date : PlainDate | undefined}
 *
 * To be noted that if tsconfig exactOptionalPropertyTypes
 * https://www.typescriptlang.org/tsconfig#exactOptionalPropertyTypes
 * is not set to true, optional gets "| undefined" added to their types
 */
export type PropagateNullOrOptional<T, TNewType> = PropagateNull<
  T,
  PropagateUndefined<T, TNewType>
>;

export type ReplaceType<T, TKeys extends keyof T, TNewType> = {
  [K in keyof T]: K extends TKeys
    ? PropagateNullOrOptional<T[K], TNewType>
    : T[K];
};

export function replace<
  TOldType,
  TNewType,
  T extends { [P in TKeys]?: TOldType | undefined | null },
  TKeys extends keyof T,
>(args: {
  object: T;
  keysToConvert: TKeys[];
  converter: (toReplace: TOldType) => TNewType;
}): ReplaceType<T, TKeys, TNewType> {
  const converted = args.keysToConvert.reduce(
    (a, key) => {
      // Handle optional
      if (!(key in args.object)) {
        return a;
      }
      const prev = args.object[key];
      const next = isDefined(prev) ? args.converter(prev as TOldType) : prev;
      return {
        ...a,
        [key]: next,
      };
    },
    {} as ReplaceType<T, TKeys, TNewType>,
  );

  return {
    ...args.object,
    ...converted,
  };
}
